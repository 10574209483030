import React, { Fragment, useState } from "react";
import Fade from "react-bootstrap/Collapse";
// import Card from "react-bootstrap/Card";

import { Link, withRouter, NavLink } from "react-router-dom";
import bar from "../../assets/images/bar.png";
import cross from "../../assets/images/cross.png";

import FinappLogo from "../../assets/images/finappLogoNew.png";

import "./NewHeader.scss";

function NewMainHeader(props) {
  const [isMenuOpen, setMenu] = useState(false);
  // const handleOpenNavBarMenu = () => {};
  return (
    <Fragment>
      <div className="row align-items-center justify-content-between navbar_wrapper sticky-top bg_7 m-0">
        <div className="col-12 col-md-2">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div style={{ padding: "5px", height: "80px" }}>
                <Link to="/">
                  <img
                    src={props.logoUrl.length ? props.logoUrl : FinappLogo}
                    alt="logo"
                    // height="81.52px"
                    // width="170px"
                    className="brand_responsive_img"
                  />
                </Link>
              </div>
              <img
                src={isMenuOpen ? cross : bar}
                alt="images"
                style={{ width: "15.43px", height: "16.22px" }}
                className="d-md-none"
                onClick={() => setMenu(!isMenuOpen)}
              />
            </div>
            <Fade
              in={isMenuOpen}
              style={{
                position: "fixed",
                top: "80px",
                width: "100%",
              }}
              className="d-md-none"
            >
              <div id="example-fade-text">
                <div className="d-flex flex-column  justify-content-center align-items-center d-md-none vertical_nav_wrapper">
                  <NavLink
                    to="/InvestmentProduct"
                    className="nav_link text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Investment Products
                  </NavLink>
                  <NavLink
                    to="/Your-Dreams"
                    className="nav_link text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Your Dreams
                  </NavLink>
                  <NavLink
                    to="/Tools-Resources"
                    className="nav_link text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Tools & Resources
                  </NavLink>
                  <NavLink
                    to="/Blogs"
                    className="nav_link text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Blogs
                  </NavLink>
                  {/* <NavLink
                    to="/Investor-Signup"
                    className="nav_link text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Knowledge Centre
                  </NavLink> */}
                  {/* <NavLink
                    to="/login/"
                    className="nav_link text_clr_2"
                    activeClassName="nav_link_active"
                  >
                    Login
                  </NavLink>
                  <NavLink
                    to="/Open-Account"
                    className="nav_link nav_link_button text_clr_7 text-nowrap"
                    activeClassName="nav_link_active"
                  >
                    Open Account
                  </NavLink> */}
                </div>
              </div>
            </Fade>
          </div>
        </div>

        <div className="col-md-7 col-lg-7 d-none d-md-block">
          <div className="d-flex flex-row justify-content-around">
            <NavLink
              to="/InvestmentProduct"
              className="nav_link text-nowrap"
              activeClassName="nav_link_active"
            >
              Investment Products
            </NavLink>
            <NavLink
              to="/Your-Dreams"
              className="nav_link text-nowrap"
              activeClassName="nav_link_active"
            >
              Your Dreams
            </NavLink>
            <NavLink
              to="/Tools-Resources"
              className="nav_link text-nowrap"
              activeClassName="nav_link_active"
            >
              Tools & Resources
            </NavLink>
            <NavLink to="/Blogs" className="nav_link text-nowrap" activeClassName="nav_link_active">
              Blogs
            </NavLink>
            {/* <NavLink
              to="/Investor-Signup"
              className="nav_link text-nowrap"
              activeClassName="nav_link_active"
            >
              Knowledge Centre
            </NavLink> */}
          </div>
        </div>
        <div className="col-md-3 col-lg-3 d-none d-md-block">
          <div className="d-flex flex-row justify-content-around">
            <NavLink to="/Login/" className="nav_link text_clr_2" activeClassName="nav_link_active">
              Login
            </NavLink>
            <NavLink
              to="/Open-Account"
              className="nav_link nav_link_button text_clr_7 text-nowrap"
              activeClassName="nav_link_active"
            >
              Open Account
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(NewMainHeader);
