let envVariables = {};
if (process.env.NODE_ENV === "production") {
  envVariables = {
    node_env: `${process.env.REACT_APP_NODE_ENV}`,
    base_url: `${process.env.REACT_APP_base_url}`,
    s3_url: `${process.env.REACT_APP_s3_url}`,
    s3_url_dream: `${process.env.REACT_APP_s3_url_dream}`,
    base_url_2: `${process.env.REACT_APP_base_url_2}`,
    base_url_3: `${process.env.REACT_APP_base_url_3}`,
    base_url_4: `${process.env.REACT_APP_base_url_4}`,
    base_url_5: `${process.env.REACT_APP_base_url_5}`,
    base_url_6: `${process.env.REACT_APP_base_url_6}`,
    base_url_7: `${process.env.REACT_APP_base_url_7}`,
    base_url_8: `${process.env.REACT_APP_base_url_8}`,
    bse_user_id: `${process.env.REACT_APP_bse_user_id}`,
    bse_member_id: `${process.env.REACT_APP_bse_member_id}`,
    bse_password: `${process.env.REACT_APP_bse_password}`,
    bse_pass_key: `${process.env.REACT_APP_bse_pass_key}`,
    partner: `${process.env.REACT_APP_partner}`,
    partner_key: `${process.env.REACT_APP_partner_key}`,
    partner_1: `${process.env.REACT_APP_partner_1}`,
    partner_key_1: `${process.env.REACT_APP_partner_key_1}`,
  };
} else if (process.env.NODE_ENV === "staging") {
  envVariables = {
    node_env: `${process.env.REACT_APP_NODE_ENV}`,
    base_url: `${process.env.REACT_APP_base_url}`,
    s3_url: `${process.env.REACT_APP_s3_url}`,
    s3_url_dream: `${process.env.REACT_APP_s3_url_dream}`,
    base_url_2: `${process.env.REACT_APP_base_url_2}`,
    base_url_3: `${process.env.REACT_APP_base_url_3}`,
    base_url_4: `${process.env.REACT_APP_base_url_4}`,
    base_url_5: `${process.env.REACT_APP_base_url_5}`,
    base_url_6: `${process.env.REACT_APP_base_url_6}`,
    base_url_7: `${process.env.REACT_APP_base_url_7}`,
    base_url_8: `${process.env.REACT_APP_base_url_8}`,
    bse_user_id: `${process.env.REACT_APP_bse_user_id}`,
    bse_member_id: `${process.env.REACT_APP_bse_member_id}`,
    bse_password: `${process.env.REACT_APP_bse_password}`,
    bse_pass_key: `${process.env.REACT_APP_bse_pass_key}`,
    partner: `${process.env.REACT_APP_partner}`,
    partner_key: `${process.env.REACT_APP_partner_key}`,
    partner_1: `${process.env.REACT_APP_partner_1}`,
    partner_key_1: `${process.env.REACT_APP_partner_key_1}`,
  };
} else {
  envVariables = {
    node_env: `${process.env.REACT_APP_NODE_ENV}`,
    base_url: `${process.env.REACT_APP_base_url}`,
    s3_url: `${process.env.REACT_APP_s3_url}`,
    s3_url_dream: `${process.env.REACT_APP_s3_url_dream}`,
    base_url_2: `${process.env.REACT_APP_base_url_2}`,
    base_url_3: `${process.env.REACT_APP_base_url_3}`,
    base_url_4: `${process.env.REACT_APP_base_url_4}`,
    base_url_5: `${process.env.REACT_APP_base_url_5}`,
    base_url_6: `${process.env.REACT_APP_base_url_6}`,
    base_url_7: `${process.env.REACT_APP_base_url_7}`,
    base_url_8: `${process.env.REACT_APP_base_url_8}`,
    bse_user_id: `${process.env.REACT_APP_bse_user_id}`,
    bse_member_id: `${process.env.REACT_APP_bse_member_id}`,
    bse_password: `${process.env.REACT_APP_bse_password}`,
    bse_pass_key: `${process.env.REACT_APP_bse_pass_key}`,
    partner: `${process.env.REACT_APP_partner}`,
    partner_key: `${process.env.REACT_APP_partner_key}`,
    partner_1: `${process.env.REACT_APP_partner_1}`,
    partner_key_1: `${process.env.REACT_APP_partner_key_1}`,
    google_analytics_tracking_id: `${process.env.REACT_APP_google_analytics_tracking_id}`,
  };
}

export default envVariables;
